// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-10-things-about-domain-names-js": () => import("./../src/pages/10-things-about-domain-names.js" /* webpackChunkName: "component---src-pages-10-things-about-domain-names-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-6-benefits-of-subscription-business-model-js": () => import("./../src/pages/6-benefits-of-subscription-business-model.js" /* webpackChunkName: "component---src-pages-6-benefits-of-subscription-business-model-js" */),
  "component---src-pages-6-steps-to-monetize-your-website-js": () => import("./../src/pages/6-steps-to-monetize-your-website.js" /* webpackChunkName: "component---src-pages-6-steps-to-monetize-your-website-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alphabet-vs-amazon-js": () => import("./../src/pages/alphabet-vs-amazon.js" /* webpackChunkName: "component---src-pages-alphabet-vs-amazon-js" */),
  "component---src-pages-bad-ideas-become-good-ideas-js": () => import("./../src/pages/bad-ideas-become-good-ideas.js" /* webpackChunkName: "component---src-pages-bad-ideas-become-good-ideas-js" */),
  "component---src-pages-best-data-web-resources-js": () => import("./../src/pages/best-data-web-resources.js" /* webpackChunkName: "component---src-pages-best-data-web-resources-js" */),
  "component---src-pages-building-something-on-the-side-js": () => import("./../src/pages/building-something-on-the-side.js" /* webpackChunkName: "component---src-pages-building-something-on-the-side-js" */),
  "component---src-pages-cleanmymac-review-js": () => import("./../src/pages/cleanmymac-review.js" /* webpackChunkName: "component---src-pages-cleanmymac-review-js" */),
  "component---src-pages-data-scientist-learn-web-development-js": () => import("./../src/pages/data-scientist-learn-web-development.js" /* webpackChunkName: "component---src-pages-data-scientist-learn-web-development-js" */),
  "component---src-pages-deploy-react-to-production-js": () => import("./../src/pages/deploy-react-to-production.js" /* webpackChunkName: "component---src-pages-deploy-react-to-production-js" */),
  "component---src-pages-fiverr-logo-design-js": () => import("./../src/pages/fiverr-logo-design.js" /* webpackChunkName: "component---src-pages-fiverr-logo-design-js" */),
  "component---src-pages-goals-and-working-with-your-subconscious-js": () => import("./../src/pages/goals-and-working-with-your-subconscious.js" /* webpackChunkName: "component---src-pages-goals-and-working-with-your-subconscious-js" */),
  "component---src-pages-how-should-i-learn-web-development-and-data-science-js": () => import("./../src/pages/how-should-i-learn-web-development-and-data-science.js" /* webpackChunkName: "component---src-pages-how-should-i-learn-web-development-and-data-science-js" */),
  "component---src-pages-how-to-create-every-day-js": () => import("./../src/pages/how-to-create-every-day.js" /* webpackChunkName: "component---src-pages-how-to-create-every-day-js" */),
  "component---src-pages-html-website-setup-cpanel-js": () => import("./../src/pages/html-website-setup-cpanel.js" /* webpackChunkName: "component---src-pages-html-website-setup-cpanel-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infographic-design-js": () => import("./../src/pages/infographic-design.js" /* webpackChunkName: "component---src-pages-infographic-design-js" */),
  "component---src-pages-infographics-for-seo-js": () => import("./../src/pages/infographics-for-seo.js" /* webpackChunkName: "component---src-pages-infographics-for-seo-js" */),
  "component---src-pages-infographics-js": () => import("./../src/pages/infographics.js" /* webpackChunkName: "component---src-pages-infographics-js" */),
  "component---src-pages-infographics-oliviareaney-js": () => import("./../src/pages/infographics/oliviareaney.js" /* webpackChunkName: "component---src-pages-infographics-oliviareaney-js" */),
  "component---src-pages-learn-skill-not-tool-js": () => import("./../src/pages/learn-skill-not-tool.js" /* webpackChunkName: "component---src-pages-learn-skill-not-tool-js" */),
  "component---src-pages-lifestyle-creep-how-to-avoid-js": () => import("./../src/pages/lifestyle-creep-how-to-avoid.js" /* webpackChunkName: "component---src-pages-lifestyle-creep-how-to-avoid-js" */),
  "component---src-pages-machine-learning-web-dev-js": () => import("./../src/pages/machine-learning-web-dev.js" /* webpackChunkName: "component---src-pages-machine-learning-web-dev-js" */),
  "component---src-pages-marketing-your-first-ebook-js": () => import("./../src/pages/marketing-your-first-ebook.js" /* webpackChunkName: "component---src-pages-marketing-your-first-ebook-js" */),
  "component---src-pages-outsourcing-infographics-js": () => import("./../src/pages/outsourcing-infographics.js" /* webpackChunkName: "component---src-pages-outsourcing-infographics-js" */),
  "component---src-pages-outsourcing-with-fiverr-js": () => import("./../src/pages/outsourcing-with-fiverr.js" /* webpackChunkName: "component---src-pages-outsourcing-with-fiverr-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-siteground-review-js": () => import("./../src/pages/siteground-review.js" /* webpackChunkName: "component---src-pages-siteground-review-js" */),
  "component---src-pages-the-best-article-type-for-seo-js": () => import("./../src/pages/the-best-article-type-for-seo.js" /* webpackChunkName: "component---src-pages-the-best-article-type-for-seo-js" */),
  "component---src-pages-the-good-and-bad-of-meta-js": () => import("./../src/pages/the-good-and-bad-of-meta.js" /* webpackChunkName: "component---src-pages-the-good-and-bad-of-meta-js" */),
  "component---src-pages-the-tech-stack-for-my-site-js": () => import("./../src/pages/the-tech-stack-for-my-site.js" /* webpackChunkName: "component---src-pages-the-tech-stack-for-my-site-js" */),
  "component---src-pages-web-setup-checklist-js": () => import("./../src/pages/web-setup-checklist.js" /* webpackChunkName: "component---src-pages-web-setup-checklist-js" */),
  "component---src-pages-website-setup-guide-js": () => import("./../src/pages/website-setup-guide.js" /* webpackChunkName: "component---src-pages-website-setup-guide-js" */),
  "component---src-pages-why-use-wordpress-js": () => import("./../src/pages/why-use-wordpress.js" /* webpackChunkName: "component---src-pages-why-use-wordpress-js" */),
  "component---src-pages-why-you-need-an-online-cv-js": () => import("./../src/pages/why-you-need-an-online-cv.js" /* webpackChunkName: "component---src-pages-why-you-need-an-online-cv-js" */),
  "component---src-pages-why-you-shouldnt-just-rely-on-seo-js": () => import("./../src/pages/why-you-shouldnt-just-rely-on-seo.js" /* webpackChunkName: "component---src-pages-why-you-shouldnt-just-rely-on-seo-js" */),
  "component---src-pages-working-for-free-can-be-good-js": () => import("./../src/pages/working-for-free-can-be-good.js" /* webpackChunkName: "component---src-pages-working-for-free-can-be-good-js" */)
}

